import { Autocomplete } from 'stimulus-autocomplete'

// stimulus-autocomplete overrides
export default class extends Autocomplete {

  connect() {
    super.connect();

    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;
  }

  // override tab key event handler
  onTabKeydown = (event) => {
    // do nothing - we use on blur in lookup controller instead
  }
}

import { Controller } from '@hotwired/stimulus';

export default class SearchController extends Controller {
  static targets = ['drugInfoStep', 'selectPharmacyStep', 'reviewStep', 'signInMessage', 'rxCard'];

  static values = {
    step: { type: Number, default: 1 },
    prescription: { type: Object, default: { text: {}, values: {} } },
  };

  constructor(...args) {
    super(...args);

    this.stepTargets = [
      this.drugInfoStepTarget,
      this.selectPharmacyStepTarget,
      this.reviewStepTarget,
    ];
  }

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;

     // listen for browser back/forward
    window.addEventListener("popstate", this.restoreBrowserState);

    this.showStep(this.stepValue, false);
  }

  disconnect() {
    window.removeEventListener("popstate", this.restoreBrowserState);
  }

  // window popstate event handler - restore browser state (back/forward)
  // this fires after history.state has been updated
  restoreBrowserState = (event) => {
    if (history.state?.search?.step) {
      // show the step saved in the history state
      this.showStep(history.state.search.step, false);
    }
  }

  showStep(step = 1, updateHistory = true) {
    // TODO: check for params that step requires?
    if (this.stepTargets[step - 1] !== undefined) {
      this.stepValue = step;

      if (updateHistory) {
        this.updateHistory({step: step});
      }

      this.stepTargets.forEach((stepTarget, stepIndex) => {
        if (stepIndex + 1 === step) {
          // show step
          stepTarget.hidden = false;
          // update page title
          this.updateTitle(stepTarget.querySelector('h1')?.textContent);
        } else {
          stepTarget.hidden = true;
        }
      });

      // scroll to top
      window.scrollTo(0, 0);

      if (typeof gtag !== 'undefined') {
        // set the page path for google analytics tracking
        gtag('set', 'page_path', window.location.pathname + window.location.search);

        if (updateHistory) {
          // track a new page view
          gtag('event', 'page_view');
        }
      }
    }
  }

  showDrugInfoStep(event) {
    event?.preventDefault();
    this.showStep(1);
  }

  showSelectPharmacyStep(event) {
    event?.preventDefault();
    this.showStep(2);
  }

  showReviewStep(event) {
    // only show step via js when logged out
    // when logged in, review step must be a new page load to allow meta tag to trigger iOS app prompt
    if (this.hasSignInMessageTarget) {
      event?.preventDefault();
      this.showStep(3);
      this.signInMessageTarget.dispatchEvent(new Event('impression'));
    }
  }

  // back link click handler
  back(event) {
    event?.preventDefault();
    if (this.stepValue >= 2) {
      this.showStep(this.stepValue - 1);
    }
  }

  // update title tag
  updateTitle(title, separator = ' | ') {
    if (document.querySelector('title')) {
      document.querySelector('title').textContent = title.concat(separator, 'ID.me Rx');
    }
  }

  updateHistory(newParams, replace = false) {
    let newQueryString = this.getUpdatedQueryString(newParams);

    // merge search step param into history state object
    let newHistoryState = {
      ...history.state,
      search: {
        step: isNaN(newParams.step) ? this.stepValue : parseInt(newParams.step)
      },
    };

    if (replace) {
      history.replaceState(newHistoryState, null, "?" + newQueryString);
    } else {
      history.pushState(newHistoryState, null, "?" + newQueryString);
    }
  }

  // update prescription details based on form data
  updatePrescription(form) {
    let prescriptionData = { text: {}, values: {}, };

    // update prescription data
    form.querySelectorAll('select').forEach(select => {
      prescriptionData.text[select.getAttribute('name')] = select.options[select.selectedIndex].text;
      prescriptionData.values[select.getAttribute('name')] = select.value;
    });

    // add ndc and quantity value from quantity data
    prescriptionData.values['ndc'] = this.drugInfoStepTarget.controllers['drug-info'].quantityDataValue.ndc;
    prescriptionData.values['quantity_value'] = this.drugInfoStepTarget.controllers['drug-info'].quantityDataValue.quantity;

    // update prescription value - must set entire object all at once
    this.prescriptionValue = prescriptionData;

    // update select pharmacy step with new prescription data and text
    let selectPharmacyController = this.selectPharmacyStepTarget.controllers['select-pharmacy'];
    selectPharmacyController.updatePrescriptionValues(this.prescriptionValue.values);
    selectPharmacyController.updatePrescriptionText(this.prescriptionValue.text);

    // update url with new prescription values
    this.updateHistory(this.prescriptionValue.values, true);
  }

  // pharmacy button click event handler
  updatePharmacy(event) {
    event?.preventDefault();

    // get data from clicked pharmacy
    let pharmacy = JSON.parse(event.currentTarget.getAttribute('data-pharmacy'));
    let index = JSON.parse(event.currentTarget.getAttribute('data-pharmacy-index'));
    let nabp = pharmacy.nabp;

    // update review step content
    let reviewController = this.reviewStepTarget.controllers['review'];
    reviewController.updatePharmacy(pharmacy);

    // update url with pharmacy index and nabp
    this.updateHistory({
      'pharmacy_index': index,
      'pharmacy_nabp': nabp
    }, true);
  }

  getUpdatedQueryString(newParams) {
    // get current params
    const queryParams = new URLSearchParams(window.location.search);

    // add new params
    Object.entries(newParams).forEach((entry) => {
      const [key, val] = entry;
      queryParams.set(key, val);
    });

    // return query string
    return queryParams.toString();
  }

}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'input', 'hidden', 'results', 'noResults'];
  static values = {};

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;

    this.inputTarget.addEventListener('input', this.trackInput);

    // automatically focus input
    if(window.location.hash == `#${this.inputTarget.getAttribute('id')}`) {
      this.inputTarget.focus();
    }
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.trackInput);
  }

  // autocomplete load event handler
  // triggered by autocomplete controller when new autocomplete results have loaded
  updateResults(e) {
    let firstItem = this.resultsTarget.querySelector('li');
    if (firstItem) {
      // highlight first item
      firstItem.classList.add('active');
      firstItem.setAttribute('aria-selected', 'true');
      this.inputTarget.setAttribute('aria-activedescendant', 'drug-lookup-autocomplete-item-0');
      // hide no results message
      this.noResultsTarget.setAttribute('hidden', true);
    } else {
      this.inputTarget.removeAttribute('aria-activedescendant');
    }
  }

  // list-group-item mouseover event handler
  highlightItem(e) {
    // highlight item on hover
    let itemToHighlight = e.currentTarget;
    let highlightedItem = this.resultsTarget.querySelector('li.active');

    // un-highlight old item
    if (highlightedItem) {
      highlightedItem.classList.remove('active');
      highlightedItem.removeAttribute('aria-selected');
    }

    // highlight new items
    itemToHighlight.classList.add('active');
    itemToHighlight.setAttribute('aria-selected', 'true');
  }

  // input blur event handler
  useHighlightedItem(e) {
    // use highlighted item as the field value
    let highlightedItem = this.resultsTarget.querySelector('li.active');
    if (highlightedItem) {
      let value = highlightedItem.textContent.trim();
      this.inputTarget.value = value;
      this.hiddenTarget.value = value;
    }
  }

  // autocomplete.change event handler
  // triggered by autocomplete controller when autocomplete value is selected
  selectDrug(e) {
    // track event before submitting
    this.trackSubmit();
  }

  // form submit event handler
  validate(e) {
    if (!this.hiddenTarget.value || this.hiddenTarget.value == '') {
      e.preventDefault();
      this.noResultsTarget.removeAttribute('hidden');
    } else {
      this.noResultsTarget.setAttribute('hidden', true);
      // track event before submitting
      this.trackSubmit(e);
    }
  }

  // input event handler
  // track when user inputs text into the drug lookup search field
  // only track the first time
  trackInput = (e) => {
    // remove listener so we don't track additional times
    this.inputTarget.removeEventListener('input', this.trackInput);

    // manually track event
    this.element.controllers['tracking'].trackEvent({
      category: 'drug-lookup',
      action: 'input',
      name: 'drug_lookup_input',
    });
  }

  // track form submission and delay submit until it is tracked
  trackSubmit(e) {
    let trackingController = this.element.controllers['tracking'];
    let trackingData = trackingController.getTrackingData();

    // delay submitting form
    // submit will be triggered by ga callback or tracking controller timeout
    trackingController.delayFormSubmit(e);

    // set tracking event label and callback
    trackingData.label = this.hiddenTarget.value;
    trackingData.options.event_callback = trackingController.submitForm;
    // ga4 params
    trackingData.params = {
      label: this.hiddenTarget.value,
    };

    // track event
    trackingController.trackEvent(trackingData);
  }

  // form reset event handler
  clear(e) {
    // hide no results message
    this.noResultsTarget.setAttribute('hidden', true);
    // focus input
    this.inputTarget.focus();
  }
}

import { Controller } from '@hotwired/stimulus';

export default class NavController extends Controller {
  static targets = ['signInMessage'];

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;
  }

  disconnect() {
    this.element.removeEventListener('focusout', this.checkFocus);
    document.removeEventListener('keyup', this.checkEscKey);
    document.removeEventListener('click', this.checkClickTarget);
  }

  open(trigger) {
    trigger.setAttribute('aria-expanded', true);

    if (this.hasSignInMessageTarget && this.signInMessageTarget.matches('[aria-expanded="true"] + * [data-tracking-trigger-value="impression"]')) {
      this.signInMessageTarget.dispatchEvent(new Event('impression'));
    }

    this.element.addEventListener('focusout', this.checkFocus);
    document.addEventListener('keyup', this.checkEscKey);
    document.addEventListener('click', this.checkClickTarget);
  }

  close(trigger = this.element.querySelector('[aria-expanded="true"]')) {
    trigger.setAttribute('aria-expanded', false);

    this.element.removeEventListener('focusout', this.checkFocus);
    document.removeEventListener('keyup', this.checkEscKey);
    document.removeEventListener('click', this.checkClickTarget);
  }

  // toggle button click event handler
  toggle(event) {
    let trigger = event.currentTarget;
    let open = trigger.getAttribute('aria-expanded') == 'true';

    if (open) {
      this.close(trigger);
    } else {
      this.open(trigger);
    }
  }

  // focusout event handler: close mobile hamburger nav when esc key is pressed
  checkFocus = (e) => {
    if (e.relatedTarget && !this.element.contains(e.relatedTarget)) {
      this.close();
    }
  }

  // keyup event handler: close mobile hamburger nav when esc key is pressed
  checkEscKey = (e) => {
    if (e.code === 'Escape') {
      this.close();
    }
  }

  // click event handler: close mobile hamburger nav when clicking outside nav
  checkClickTarget = (e) => {
    if (!this.element.contains(e.target)) {
      this.close();
    }
  }
}

import { Controller } from '@hotwired/stimulus';
export default class SelectPharmacyController extends Controller {
  static targets = ['prescriptionDrug', 'prescriptionForm', 'prescriptionDosage', 'prescriptionQuantity', 'form', 'sort', 'drugHidden', 'formHidden', 'dosageHidden', 'quantityHidden', 'sortHidden', 'ndcHidden', 'quantityValueHidden', 'postalCodeField', 'postalCodeInput', 'postalCodeError', 'results'];

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;

    // TODO: find a better way to reference search controller
    this.searchController = document.querySelector('[data-controller=search]')?.controllers['search'];
  }

  // pharmacy_sort_select change event handler
  // TODO: should we sort on frontend instead of making new request?
  // would be faster and results are limited to small number (no pagination)
  sortPharmacies(event) {
    // update hidden field value to match select value
    this.sortHiddenTarget.value = event.currentTarget.value;
    // track event
    this.trackSort();
    // resubmit form - requestSubmit triggers turbo
    this.formTarget.requestSubmit();
    // update url
    this.searchController?.updateHistory({pharmacy_sort: this.sortHiddenTarget.value}, true);
  }

  // form turbo:submit-start event handler
  validatePostalCode(event) {
    this.postalCodeInputTarget.value = this.postalCodeInputTarget.value.trim();

    // todo: for some reason checkValidity returns true when this field is empty
    if (this.postalCodeInputTarget.value.length == 5 && this.postalCodeInputTarget.checkValidity()) {
      // remove error message
      this.postalCodeErrorTarget.setAttribute('hidden', true);
      this.postalCodeFieldTarget.classList.remove('error');
      this.postalCodeInputTarget.removeAttribute('aria-invalid');
      this.postalCodeInputTarget.removeAttribute('aria-describedby');
      // update url
      this.searchController?.updateHistory({'postal_code': this.postalCodeInputTarget.value}, true);
      // track submit
      this.trackSubmit(event);
    } else {
      // do not search
      event.preventDefault();
      event.detail?.formSubmission.stop();
      // show error message
      this.postalCodeErrorTarget.removeAttribute('hidden');
      this.postalCodeFieldTarget.classList.add('error');
      this.postalCodeInputTarget.setAttribute('aria-invalid', true);
      this.postalCodeInputTarget.setAttribute('aria-describedby', 'postal-code-error');
    }
  }

  setResultsLoading() {
    this.resultsTarget.classList.add('loading');
  }

  unsetResultsLoading() {
    this.resultsTarget.classList.remove('loading');
  }

  trackSort() {
    let trackingController = this.sortTarget.controllers['tracking'];
    let trackingData = trackingController.getTrackingData();

    // set tracking event label
    trackingData.label = this.sortHiddenTarget.value;
    // ga4
    trackingData.params = {
      label: this.sortHiddenTarget.value,
    };

    // track event
    trackingController.trackEvent(trackingData);
  }

  trackSubmit() {
    let trackingController = this.formTarget.controllers['tracking'];
    let trackingData = trackingController.getTrackingData();

    // set tracking event label
    trackingData.label = this.postalCodeInputTarget.value;
    // ga4
    trackingData.params = {
      label: this.postalCodeInputTarget.value,
    };

    // track event
    trackingController.trackEvent(trackingData);
  }

  // update hidden fields with prescription data and reset results
  updatePrescriptionValues(prescriptionData) {
    // only update and clear if at least one value has changed
    if (this.ndcHiddenTarget.value != prescriptionData['ndc'] || this.quantityValueHiddenTarget.value != prescriptionData['quantity_value']) {
      this.drugHiddenTarget.value = prescriptionData['drug'];
      this.formHiddenTarget.value = prescriptionData['form'];
      this.dosageHiddenTarget.value = prescriptionData['dosage'];
      this.quantityHiddenTarget.value = prescriptionData['quantity'];
      this.ndcHiddenTarget.value = prescriptionData['ndc'];
      this.quantityValueHiddenTarget.value = prescriptionData['quantity_value'];
      this.clearResults();
    }
  }

  // update prescription text
  updatePrescriptionText(prescriptionText) {
    this.prescriptionDrugTarget.textContent = prescriptionText.drug;
    this.prescriptionFormTarget.textContent = prescriptionText.form;
    this.prescriptionDosageTarget.textContent = prescriptionText.dosage;
    this.prescriptionQuantityTarget.textContent = prescriptionText.quantity;
  }

  // clear pharmacy results
  clearResults() {
    this.resultsTarget.innerHTML = '';
  }
}

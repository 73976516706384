import { Controller } from '@hotwired/stimulus';
export default class DrugInfoController extends Controller {
  static targets = ['form', 'drugSelect', 'formSelect', 'dosageSelect', 'quantitySelect', 'ndcHidden', 'quantityHidden'];

  static values = {
    drugComponents: Object,
    quantities: Array, // all quantity data objects for selected options
    quantityData: Object, // quantity data object for selected quantity
  };

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;

    this.searchController = document.querySelector('[data-controller=search]')?.controllers['search'];

    if (Object.keys(this.drugComponentsValue).length > 0) {
      const queryParams = new URLSearchParams(window.location.search);

      // use values from url if valid
      let selectedValues = {
        // for first field (drug) only, use glic default if no valid query param
        drugIndex: this.parseIndex(queryParams.get('drug')) ?? this.drugComponentsValue.defaults.drugIndex,
        formIndex: this.parseIndex(queryParams.get('form')),
        dosageIndex: this.parseIndex(queryParams.get('dosage')),
        quantityIndex: this.parseIndex(queryParams.get('quantity')),
      };

      this.updateDrugs(selectedValues);
    }
  }

  parseIndex(param) {
    // parse a query param string into an integer that can be used as an index, or null if not valid
    let index = parseInt(param);
    return isNaN(index) ? null : index;
  }

  // eslint-disable-next-line class-methods-use-this
  buildSelectOptions(selectBox, items, selectedIndex, isDrugName = false) {
    // eslint-disable-next-line no-param-reassign
    selectBox.length = 0;
    selectBox.disabled = true;

    // first option is a blank disabled option with placeholder text
    const blankOption = document.createElement('option');
    blankOption.value = '';
    blankOption.innerHTML = items?.length ? selectBox.dataset.placeholder : '--';
    blankOption.disabled = true;
    selectBox.appendChild(blankOption);

    if (items?.length) {
      selectBox.disabled = false;

      items.forEach((item, index) => {
        const opt = document.createElement('option');
        opt.value = index;
        opt.innerHTML = isDrugName ? `${item[0]} - ${item[1]}` : item;
        selectBox.appendChild(opt);
      });
    }

    // select the correct option once they are added
    this.selectOption(selectBox.options, selectedIndex);
  }

  selectOption(options, selectedIndex = null) {
    if (options.length) {
      // parse index to ensure it is an integer or null
      selectedIndex = this.parseIndex(selectedIndex);

      let blankOffset = options[0].disabled ? 1 : 0;

      // must add one to total to account for first blank option
      if (options.length == 1 + blankOffset) {
        // select first selectable option if it's the only one
        options[0 + blankOffset].selected = true;
      } else if (selectedIndex !== null && options.length > selectedIndex + blankOffset) {
        // select option corresponding to selectedIndex, if valid
        options[selectedIndex + blankOffset].selected = true;
      } else if (blankOffset) {
        // otherwise select first blank option
        options[0].selected = true;
      }
    }
  }

  updateDrugs(selectedValues = null) {
    const { drugsList } = this.drugComponentsValue;

    this.buildSelectOptions(
      this.drugSelectTarget,
      drugsList,
      selectedValues?.drugIndex ?? 0,
      true,
    );

    this.updateForms(selectedValues);
  }

  // drug select change event handler
  drugChange(event) {
    this.updateForms();
  }

  updateForms(selectedValues = null) {
    const { formsList } = this.drugSelectTarget.value ?
      this.drugComponentsValue
        .drugs[this.drugSelectTarget.value] :
      {};

    this.buildSelectOptions(
      this.formSelectTarget,
      formsList,
      selectedValues?.formIndex ?? null,
    );

    this.updateDosages(selectedValues);
  }

  // form select change event handler
  formChange(event) {
    this.updateDosages();
  }

  updateDosages(selectedValues = null) {
    const { dosagesList } = this.formSelectTarget.value ?
      this.drugComponentsValue
        .drugs[this.drugSelectTarget.value]
        .forms[this.formSelectTarget.value] :
      {};

    this.buildSelectOptions(
      this.dosageSelectTarget,
      dosagesList,
      selectedValues?.dosageIndex ?? null,
    );

    this.updateQuantities(selectedValues);
  }

  // dosage select change event handler
  dosageChange(event) {
    this.updateQuantities();
  }

  updateQuantities(selectedValues = null) {
    const { quantities, quantitiesList } = this.dosageSelectTarget.value ?
      this.drugComponentsValue
        .drugs[this.drugSelectTarget.value]
        .forms[this.formSelectTarget.value]
        .dosages[this.dosageSelectTarget.value] :
      {};

    this.buildSelectOptions(
      this.quantitySelectTarget,
      quantitiesList,
      selectedValues?.quantityIndex ?? null,
    );

    // update list of quantity objects
    this.quantitiesValue = quantities;

    this.updateQuantityData(selectedValues ? selectedValues.quantityIndex : null);
  }

  // quantity select change event handler
  quantityChange(event) {
    this.updateQuantityData(event.target.value);
  }

  updateQuantityData(quantityIndex = null) {
    let quantityData;

    if (this.quantitiesValue?.length == 1) {
      quantityData = this.quantitiesValue[0];
    } else if (quantityIndex != null && this.quantitiesValue?.length > quantityIndex) {
      quantityData = this.quantitiesValue[quantityIndex];
    }

    // update selected quantity data
    this.quantityDataValue = quantityData ?? {};

    // update hidden form fields
    this.ndcHiddenTarget.value = quantityData?.ndc ?? '';
    this.quantityHiddenTarget.value = quantityData?.quantity ?? '';
  }

  addDescribedby(element, add) {
    let describedby = element.getAttribute('aria-describedby') ?? '';

    if (!describedby.split(' ').includes(add)) {
      describedby = [describedby, add].join(' ').trim();
    }

    element.setAttribute('aria-describedby', describedby);
  }

  removeDescribedby(element, remove) {
    let describedby = element.getAttribute('aria-describedby') ?? '';

    describedby = describedby.split(' ').filter(id => id != remove).join(' ').trim();

    element.setAttribute('aria-describedby', describedby);
  }

  validateField(input) {
    let field = input.closest('.field');
    let error = field.querySelector('.error-message');
    let valid = input.checkValidity() && !input.disabled;

    if (valid) {
      // remove error message
      error.setAttribute('hidden', true);
      field.classList.remove('error');
      input.removeAttribute('aria-invalid');
      this.removeDescribedby(input, error.getAttribute('id'));
    } else {
      // show error message
      error.removeAttribute('hidden');
      field.classList.add('error');
      input.setAttribute('aria-invalid', true);
      this.addDescribedby(input, error.getAttribute('id'));
    }

    return valid;
  }

  validateForm(event) {
    event.preventDefault();

    let drugsValid = this.validateField(this.drugSelectTarget);
    let formsValid = this.validateField(this.formSelectTarget);
    let dosagesValid = this.validateField(this.dosageSelectTarget);
    let quantitiesValid = this.validateField(this.quantitySelectTarget);

    if (drugsValid && formsValid && dosagesValid && quantitiesValid) {
      this.searchController.updatePrescription(this.formTarget);
      this.searchController.showSelectPharmacyStep();
    }
  }

}

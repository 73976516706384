import { Controller } from '@hotwired/stimulus';

export default class TrackingController extends Controller {
  static values = {
    trigger: String,
    category: String,
    action: String,
    label: String,
    value: Number,
    name: String,
    params: Object,
    delay: { type: Number, default: 1000 },
    delayCallbackCalled: Boolean,
  };

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;

    if (this.triggerValue) {
      // listen for trigger to track event
      this.element.addEventListener(this.triggerValue, this.triggerEvent);
    } else {
      // if no trigger, track event immediately
      let data = this.getTrackingData();
      this.trackEvent(data);
    }

    // dispatch event that controller is connected
    this.element.dispatchEvent(new Event('connect'));
  }

  disconnect() {
    if (this.triggerValue) {
      this.element.removeEventListener(this.triggerValue, this.triggerEvent);
    }
  }

  getTrackingData() {
    let options = {};

    // set impressions to non-interaction so we don't skew engagement metrics
    if (this.actionValue == 'Impression' || this.triggerValue == 'impression') {
      options.nonInteraction = true;
    }

    if (this.checkLinkDelay(this.element)) {
      options.event_callback = this.visitLink;
    } else if (this.checkFormDelay(this.element)) {
      options.event_callback = this.submitForm;
    }

    return {
      category: this.categoryValue,
      action: this.actionValue,
      label: this.labelValue,
      value: this.valueValue,
      name: this.nameValue,
      params: this.paramsValue,
      options: options,
    };
  }

  // check for external url (different protocol, domain name, or port)
  checkExternalUrl(url) {
    if (!url) {
      return false;
    }

    // same protocol
    if (url.startsWith('//')) {
      url = url.replace('//', `${window.location.protocol}//`);
    }

    // compare absolute url
    if (url.startsWith('http://') || url.startsWith('https://')) {
      let urlObject = new URL(url);

      if (
        urlObject.protocol != window.location.protocol
        || urlObject.host != window.location.host
        || urlObject.port != window.location.port
      ) {
        return true;
      }
    }

    // relative urls or matching absolute urls are internal
    return false;
  }

  // check if turbo is enabled for an element
  checkTurboEnabled(element) {
    if (typeof Turbo === 'undefined' || !Turbo.session) {
      return false;
    } else if (
      // disabled globally and not enabled on element or parent
      Turbo.session.drive == false
      && !element.matches('[data-turbo="true"], [data-turbo="true"] *')
    ) {
      return false;
    } else if (
      // enabled globally and disabled on element or parent
      Turbo.session.drive == true
      && element.matches('[data-turbo="false"], [data-turbo="false"] *')
    ) {
      return false;
    } else {
      let tag = element.tagName.toLowerCase();
      let url;

      if (tag == 'a') {
        url = element.getAttribute('href');
      } else if (tag == 'form') {
        url = element.getAttribute('action');
      } else {
        return false;
      }

      // disabled for external urls
      return !this.checkExternalUrl(url);
    }
  }

  // check if this is a link that needs click to be delayed
  checkLinkDelay(link) {
    return (
      this.triggerValue == 'click'
      && this.delayValue > 0
      && link.matches('a[href]')
      && !link.matches('[target="_blank"],[data-remote="true"]')
      && !link.getAttribute('href').startsWith('#')
      && !this.checkTurboEnabled(link)
    );
  }

  // check if this is a form that needs submit to be delayed
  checkFormDelay(form) {
    return (
      this.triggerValue == 'submit'
      && this.delayValue > 0
      && !form.matches('form[data-remote="true"]')
      && !this.checkTurboEnabled(form)
    );
  }

  delayLinkClick(event) {
    event?.preventDefault();

    // set timeout to visit link after delay in case ga never triggers callback
    setTimeout(this.visitLink, this.delayValue);
  }

  delayFormSubmit(event) {
    event?.preventDefault();

    // set timeout to submit form after delay in case ga never triggers callback
    setTimeout(this.submitForm, this.delayValue);
  }

  // trigger event handler
  // set up event tracking data and call track()
  triggerEvent = (event) => {
    let data = this.getTrackingData();

    // delay link clicks and form submits
    // this is required because js will stop executing when navigating away from the page
    // more info: https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data#know_when_an_event_has_been_sent
    if (this.checkLinkDelay(this.element)) {
      this.delayLinkClick(event);
    } else if (this.checkFormDelay(this.element)) {
      this.delayFormSubmit(event);
    }

    this.trackEvent(data);
  }

  visitLink = () => {
    if (!this.delayCallbackCalledValue) {
      this.delayCallbackCalledValue = true;
      window.location.assign(this.element.getAttribute('href'));
    }
  };

  submitForm = () => {
    if (!this.delayCallbackCalledValue) {
      this.delayCallbackCalledValue = true;
      this.element.submit();
    }
  };

  // send event to ga
  trackEvent(data) {
    if (typeof gtag !== 'undefined') {

      if (window.settings?.google_analytics_id) {
        let gtag_options = {
          event_category: data.category,
          event_label: data.label,
          value: data.value,
          ...data.options,
        };
        gtag('event', data.action, gtag_options);
      }

      if (window.settings?.ga4_measurement_id && data.name) {
        if (data.options?.event_callback) {
          data.params.event_callback = data.options.event_callback;
        }
        gtag('event', data.name, data.params);
      }
    }
  }
}

import { Controller } from '@hotwired/stimulus';
export default class ReviewController extends Controller {
  static targets = ['prescription', 'prescriptionDrug', 'prescriptionForm', 'prescriptionDosage', 'prescriptionQuantity', 'pharmacy', 'pharmacyName', 'pharmacyPrice', 'pharmacyAddress', 'signInMessage', 'rxCard'];

  connect() {
    // set a reference to this controller on the element it's connected to
    this.element.controllers = this.element.controllers ?? {};
    this.element.controllers[this.identifier] = this;

    // trigger impression tracking if visible
    if (!this.element.hidden) {
      if (this.hasSignInMessageTarget) {
        this.trackImpression(this.signInMessageTarget);
      } else if (this.hasRxCardTarget) {
        this.trackImpression(this.rxCardTarget);
      }
    }
  }

  trackImpression(impressionTarget) {
    // this may be called before the tracking controller connects, so we must
    // check if tracking controller is attached
    if (impressionTarget.controllers && impressionTarget.controllers['tracking']) {
      // if attached, dispatch impression event to trigger tracking
      impressionTarget.dispatchEvent(new Event('impression'));
    } else {
      // if not attached, update to trigger when tracking controller connects
      impressionTarget.setAttribute('data-tracking-trigger-value', 'connect');
    }
  }
}
